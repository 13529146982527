<template>
  <div>
    <div class="tipOne">{{$t('SItemap.SI_tip')}}</div>
    <div class="outer">
      <div
        v-for="(item, index) in $t('SItemap.SI_modular')"
        :key="index"
        class="modular"
      >
        <div
          v-for="(item2, index2) in item"
          :key="index2"
          :class="
            $i18n.locale == 'zh' ? 'modularItem' : 'modularItem modularItemTwo'
          "
        >
          <div v-if="index2 == 0" class="itemTipOne">
            {{ item2.name }}
          </div>
          <div v-if="index2 > 0" class="itemTipTwo" @click="jumpPages(item2)">
            {{ item2.name }}
          </div>
          <div v-if="index2 > 0" class="xian"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：Sitemap
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-23 18:07
 */
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    /**
     * 跳转相应的页面
     * 刘嘉鑫
     * 2022-8-24
     */
    jumpPages(item2) {
      this.$router.push({
        path: item2.path,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
  padding: 80px 310px;
}
.tipOne {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 60px;
}

.modular {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.modularItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .itemTipOne {
    background: #1a2a60;
    padding: 26px 10px;
    width: 140px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  .itemTipTwo {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    padding: 24px 40px;
    text-align: center;
  }

  .xian {
    width: 2px;
    height: 24px;
    // margin-left: 6px;
    background: #1a2a60;
  }
}

.modularItemTwo {
  .itemTipOne{
     width: 160px;
  }
  .itemTipTwo {
    padding: 24px 20px !important;
  }
}
</style>